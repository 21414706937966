<template>
  <b-row class="match-height">
    <b-col cols="12" md="3" class="mb-md-0 mb-2">
      <label>Date</label>
      <date-range-picker v-model="pickerDates" :config="config" :locale-data="locale" @update="dateChange">
      </date-range-picker>
      <br>
    </b-col>
    <b-col cols="12">
      <div class="card card-statistics">
        <!---->
        <!---->
        <div class="card-header">
          <h4 class="card-title">Statistics</h4>
          <p class="card-text font-small-2 mr-25 mb-0"> Updated few seconds ago </p>
        </div>
        <div class="card-body statistics-body">
          <!---->
          <!---->
          <div class="row">
            <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
              <div class="media">
                <div class="media-aside mr-2 align-self-start">
                  <span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px;">
                    <span class="b-avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-dollar-sign">
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg>
                    </span>
                    <!----></span>
                </div>
                <div class="media-body my-auto">
                  <h4 class="font-weight-bolder mb-0">Rp{{ (Number(totalIDR).toLocaleString()) }} </h4>
                  <p class="card-text font-small-3 mb-0"> Total Invoice IDR </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3">
              <div class="media">
                <div class="media-aside mr-2 align-self-start"><span class="b-avatar badge-light-success rounded-circle"
                    style="width: 48px; height: 48px;"><span class="b-avatar-custom"><svg
                        xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-dollar-sign">
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg></span>
                    <!----></span></div>
                <div class="media-body my-auto">
                  <h4 class="font-weight-bolder mb-0">${{ (Number(totalUSD).toLocaleString()) }} </h4>
                  <p class="card-text font-small-3 mb-0"> Total Invoice USD </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
              <div class="media">
                <div class="media-aside mr-2 align-self-start"><span class="b-avatar badge-light-danger rounded-circle"
                    style="width: 48px; height: 48px;"><span class="b-avatar-custom">
                      <feather-icon
              icon="MinusIcon"
              size="25"
              class="mr-90"
            />
                    </span>
                    <!----></span></div>
                <div class="media-body my-auto">
                  <h4 class="font-weight-bolder mb-0"> {{kFormatter(totalUnconfirmed)}} </h4>
                  <p class="card-text font-small-3 mb-0"> Total Unconfirmed</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
              <div class="media">
                <div class="media-aside mr-2 align-self-start"><span class="b-avatar badge-light-success rounded-circle"
                    style="width: 48px; height: 48px;"><span class="b-avatar-custom">
                      <feather-icon
              icon="CheckIcon"
              size="25"
              class="mr-90"
            />
                    </span>
                    <!----></span></div>
                <div class="media-body my-auto">
                  <h4 class="font-weight-bolder mb-0"> {{kFormatter(totalConfirmed)}} </h4>
                  <p class="card-text font-small-3 mb-0"> Total Confirmed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </b-col>
    <b-col md="6">
      <template>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Invoice IDR</b-card-title>
            <!-- datepicker -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
            </div>
            <!-- datepicker -->
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-bar-chart
              v-if="totalIDR>0"
              :key="invoice_idr_bar"
              :height="400"
              :data="dataBarIDR"
              :options="chartjsData.latestBarChart.options"
            />
          </b-card-body>
        </b-card>
      </template>
    </b-col>
    <b-col md="6">
      <template>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Invoice USD
              </b-card-title>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-bar-chart
              v-if="totalUSD>0"
              :key="invoice_idr_bar"
              :height="400"
              :data="dataBarUSD"
              :options="chartjsData.latestBarChart.options"
            />
          </b-card-body>
        </b-card>
      </template>
    </b-col>

    <b-col cols="12">
      <template>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Invoice Income Table (IDR)
              </b-card-title>
            </div>
          </b-card-header>
          <b-table striped hover :items="itemsIdr" :fields="fields"></b-table>
          <b-card-body>
          </b-card-body>
          </b-card>
          </template>
    </b-col>

    <b-col cols="12">
      <template>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Invoice Income Table (USD)
              </b-card-title>
            </div>
          </b-card-header>
          <b-table striped hover :items="itemsUSD" :fields="fields"></b-table>
          <b-card-body>
          </b-card-body>
          </b-card>
          </template>
    </b-col>
  </b-row>
</template>

<script>
import { $themeColors } from '@themeConfig'
  import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
  } from 'bootstrap-vue'

  import {
    getToken,
  } from '@/auth/utils'
  import axios from 'axios'
  import moment from 'moment'
  import DateRangePicker from 'vue2-daterange-picker'
  import { kFormatter } from '@core/utils/filter'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
  import chartjsData from './chartjsData'

  const userToken = getToken()
  const headers = {
    'X-Token-Access': `Bearer ${userToken}`,
    'Content-Type': 'application/json',
  }
  const totalIDR = 0
  const totalUSD = 0
  const totalConfirmed = 0
  const totalUnconfirmed = 0
  const startDate = new Date()
  const endDate = new Date()
  startDate.setDate(startDate.getDate() - 30)
  const labelsIDR = []
  const labelsUSD = []
  const dataIDR = []
  const dataUSD = []
  const itemsIdr = []
  const itemsUSD = []
  export default {
    components: {
      BTable,
      BCard,
      BCardHeader,
      BCardBody,
      BCardTitle,
      BRow,
      BCol,
      DateRangePicker,
      ChartjsComponentBarChart,
    },
    mounted() {
      this.getExecutive()
    },
    data() {
      return {
        fields: ['branch', 'kode_unit_kerja', 'currency', 'nominal'],
        itemsIdr,
        itemsUSD,
      plugins: [
          // to add spacing between legends and chart
          {
            beforeInit(chart) {
              /* eslint-disable func-names, no-param-reassign */
              chart.legend.afterFit = function () {
                this.height += 20
              }
              /* eslint-enable */
            },
          },
        ],
        invoice_idr_bar: 0,
        dataBarIDR: {
          labels: labelsIDR,
          datasets: [
            {
              data: dataIDR,
              backgroundColor: '#28dac6',
              borderColor: 'transparent',
            },
          ],
        },
        dataBarUSD: {
          labels: labelsUSD,
          datasets: [
            {
              data: dataUSD,
              backgroundColor: '#ff4961',
              borderColor: 'transparent',
            },
          ],
        },
        dataLineUSD: {
      labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
      datasets: [
        {
          data: [80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290, 360, 375],
          label: 'Europe',
          borderColor: '#ff4961',
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: '#ff4961',
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: $themeColors.white,
          pointHoverBackgroundColor: '#ff4961',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        },
        {
          data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210, 200, 280],
          label: 'Asia',
          borderColor: '#666ee8',
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: '#666ee8',
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: $themeColors.white,
          pointHoverBackgroundColor: '#666ee8',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        },
      ],
    },
        labelsIDR,
        labelsUSD,
        dataIDR,
        dataUSD,
        chartjsData,
        kFormatter,
        totalIDR,
        totalUSD,
        totalConfirmed,
        totalUnconfirmed,
        startDate,
        endDate,
        config: {
          wrap: true,
          dateFormat: 'Y-m-d',
        },
        pickerDates: {
          startDate: typeof this.$route.params.startDate !== 'undefined' ? new Date(this.$route.params.startDate) : startDate,
          endDate: typeof this.$route.params.endDate !== 'undefined' ? new Date(this.$route.params.endDate) : endDate,
        },
        locale: {
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          firstDay: 1,
        },
      }
    },
    methods: {
      async getExecutive() {
        dataIDR.length = 0
        for (let i = 0; i < dataIDR.length; i += 1) {
          console.log(dataIDR[i])
          dataIDR.pop()
        }
        for (let i = 0; i < dataUSD.length; i += 1) {
          console.log(dataUSD[i])
          dataUSD.pop()
        }
        if (dataIDR.length === 0 && dataUSD.length === 0) {
        const todaysDate = typeof this.$route.params.startDate !== 'undefined' ? new Date(this.$route.params.startDate) : this.startDate
        const yyyy = todaysDate.getFullYear().toString()
        const mm = (todaysDate.getMonth() + 1).toString()
        const dd = todaysDate.getDate().toString()
        const mmChars = mm.split('')
        const ddChars = dd.split('')
        const month = mmChars[1] ? mm : `0${mmChars[0]}`
        const day = ddChars[1] ? dd : `0${ddChars[0]}`
        const fullToday = `${yyyy}-${month}-${day}`
        const aMonthAgo = typeof this.$route.params.endDate !== 'undefined' ? new Date(this.$route.params.endDate) : endDate
        const yyyy2 = aMonthAgo.getFullYear().toString()
        const mm2 = (aMonthAgo.getMonth() + 1).toString()
        const dd2 = aMonthAgo.getDate().toString()
        const mmChars2 = mm2.split('')
        const ddChars2 = dd2.split('')
        const month2 = mmChars2[1] ? mm2 : `0${mmChars2[0]}`
        const day2 = ddChars2[1] ? dd2 : `0${ddChars[0]}`
        const fullOneMonthAgo = `${yyyy2}-${month2}-${day2}`
        const body = {
          DateFrom: fullToday,
          DateTo: fullOneMonthAgo,
        }
        const url = 'https://api.geolims.com/dashboard_service/Executive'
        const res = await axios
          .post(url, body, {
            headers,
          })
          .catch(err => {
            // same, log whatever you want here
            console.log(err)
          }).catch(error => {
            console.log(error.toJSON())
          })
        if (res.data.Status === 1) {
          this.totalUSD = 0
          this.totalIDR = 0
          this.totalConfirmed = res.data.Payload['Invoice-Confirmed'][0].InvoiceConfirmed
          this.totalUnconfirmed = res.data.Payload['Invoice-UnConfirmed'][0].InvoiceUnConfirmed
          await res.data.Payload['ExternalNett-IDR'].forEach(item => {
            const temp = {
              kode_unit_kerja: item.KodeUnitKerja, branch: item.Kota, currency: 'IDR', nominal: Number(item['incomeReport-IDR']).toLocaleString(),
            }
            this.itemsIdr.push(temp)
            this.labelsIDR.push(item.Kota)
            this.dataIDR.push(parseInt(item['incomeReport-IDR'], 10) / 1000)
            this.totalIDR += parseInt(item['incomeReport-IDR'], 10)
          })
          await res.data.Payload['ExternalNett-USD'].forEach(item => {
            const temp = {
              kode_unit_kerja: item.KodeUnitKerja, branch: item.Kota, currency: 'USD', nominal: Number(item['incomeReport-USD']).toLocaleString(),
            }
            this.itemsUSD.push(temp)
            this.labelsUSD.push(item.Kota)
            this.dataUSD.push(parseInt(item['incomeReport-USD'], 10) / 1000)
            this.totalUSD += parseFloat(item['incomeReport-USD'], 10)
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              constiant: 'danger',
              text: 'Something went wrong',
            },
          })
        }
      }
      },
      dateChange(val) {
        for (let i = 0; i < dataUSD.length; i += 1) {
          dataUSD.pop()
        }
        for (let i = 0; i < dataIDR.length; i += 1) {
          dataIDR.pop()
        }
        const fromDate = moment(val.startDate).format('YYYY-MM-DD')
        const toDate = moment(val.endDate).format('YYYY-MM-DD')
        window.location.href = `${window.location.origin}/apps/income/external_nett/${fromDate}/${toDate}`
      },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
      forceRerender() {
        this.invoice_idr_bar += 1
      },
    },
  }

</script>
